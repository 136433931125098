/* eslint-disable no-param-reassign */
import VisibilityState from './track-view';
// import { SegmentWrapper } from './segment';

function deepEqual(val1, val2) {
  if (val1 === val2) return true;
  if (typeof val1 === 'object') {
    for (const key in val1) {
      if (!deepEqual(val1[key], val2[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

class TrackViewElement extends VisibilityState {
  constructor(el, val, vnode) {
    super(el, val, vnode);

    const options = {};

    options.callback = (result, _entry, value) => {
      if (result) {
        console.log('Element is visible', value);
        // SegmentWrapper.elementViewed(value);
      }
    };
    options.once = true;
    options.throttle = 1000;
    options.threshold = 0.2;
    options.value = val;

    this.createObserver(options, vnode);
  }
}

function unbind(el) {
  const state = el._vue_visibilityState;
  if (state) {
    state.destroyObserver();
    delete el._vue_visibilityState;
  }
}

function bind(el, binding, vnode) {
  if (!binding.value) { return; }
  if (typeof IntersectionObserver === 'undefined') {
    console.warn('[vue-observe-visibility] IntersectionObserver API is not available in your browser. Please install this polyfill: https://github.com/w3c/IntersectionObserver/tree/master/polyfill');
  } else {
    const state = new TrackViewElement(el, binding.value, vnode);
    el._vue_visibilityState = state;
  }
}

function update(el, binding, vnode) {
  if (deepEqual(binding.value, binding.oldValue)) return;
  const state = el._vue_visibilityState;
  if (!binding.value) {
    unbind(el);
    return;
  }
  if (state) {
    state.createObserver(binding.value, vnode);
  } else {
    bind(el, binding, vnode);
  }
}

export const TrackView = {
  bind,
  update,
  unbind,
};
