import { useAnalytics } from '@fc/app-common';
import sessionData from '@/shared/utils/sessionData';
import ordersApi from './ordersService';

function getMembership({ getters }) {
  if (getters?.membershipInCart) {
    return {
      id: getters?.membershipInCart?.metadata?.stripeSubId,
      trialDays: parseInt(getters.membershipInCart.metadata.stripeTrialDays, 10),
      stripeCoupon: getters.membershipInCart.metadata.stripeCoupon,
    };
  }

  return null;
}

function formatOrderRequestBody(params) {
  const {
    shippingDetails,
    cart,
    costBreakdown,
    shippingRateDetails,
    customerId = null,
    isPosOn,
    draftOrderId = null,
    includesConsole = null,
    paymentIntentId = null,
    setupIntentId = null,
  } = params;

  const {
    email, address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
  } = shippingDetails;

  const retailTag = isPosOn ? 'InsideSales' : 'BizDev';

  return {
    order: {
      currency: 'usd',
      email,
      itemList: cart,
      shippingDetails: {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        phone: phoneNumber,
        address,
        address2,
        city,
        state,
        country,
        postalCode,
      },
      tags: [
        retailTag,
        'Shopify API',
        ...(includesConsole ? ['includesConsole'] : []),
      ],
    },
    shippingRateDetails,
    costBreakdown,
    customerId,
    setupIntentId,
    draftOrderId,
    paymentIntentId,
  };
}

function mapCartLinesForShopify(cart) {
  return cart.map(item => ({
    merchandiseId: item.id,
    quantity: item.qty,
  }));
}

const mapCartLinesForPaymentGateway = (cart, shopifyItems) => cart.map((item) => ({
  id: item.id,
  qty: item.qty,
  type: item.type,
  uid: shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
}));

function cartItemsEqual(a, b) {
  if (a.length !== b.length) return false;

  return a.every(itemA => {
    const itemB = b.find(item => item.id === itemA.id);
    return itemB && itemB.qty === itemA.qty;
  });
}

let previousCart = [];
async function createOrUpdateShopifyCart(options, cartId) {
  const {
    shippingDetails = null,
    eCommService,
    commit,
    cart = [],
    couponName,
    removeCoupon = false,
    rootGetters,
  } = options;

  let id = cartId;
  let readyCart = null;
  try {
    if (id) {
      const cartChanged = !cartItemsEqual(previousCart, cart);

      if (cartChanged) {
        readyCart = await eCommService.cartLinesReplace({
          cartId: id,
          lines: mapCartLinesForShopify(cart),
        });
        // Update previous cart after successful update
        previousCart = [...cart];
      }

      if (shippingDetails) {
        readyCart = await eCommService.updateAddress({
          cartId: id,
          addresses: [
            {
              id: rootGetters.getDeliveryAddressId,
              address: {
                deliveryAddress: {
                  address1: shippingDetails.address,
                  address2: shippingDetails.address2,
                  city: shippingDetails.city,
                  provinceCode: shippingDetails.state,
                  countryCode: shippingDetails.country,
                  zip: shippingDetails.postalCode,
                  firstName: shippingDetails.firstName,
                  lastName: shippingDetails.lastName,
                  phone: shippingDetails.phoneNumber,
                },
              },
              selected: true,
            },
          ],
          email: shippingDetails.email,
        });

        commit('setDeliveryAddressId', readyCart.deliveryAddressId, { root: true });
      }

      if (couponName) {
        const currentSubtotalInCents = rootGetters.order.costBreakdown.productCost;
        readyCart = await eCommService.updateCartCoupon({ cartId: id, discountCodes: [couponName], currentSubtotalInCents });
      }

      if (removeCoupon) {
        readyCart = await eCommService.updateCartCoupon({ cartId: id, discountCodes: [] });
      }
    } else {
      const cartRequest = {
        buyerIdentity: {
          countryCode: shippingDetails?.country ?? '',
          email: shippingDetails?.email ?? '',
          phone: shippingDetails?.phoneNumber ?? '',
        },
        lines: mapCartLinesForShopify(cart),
      };

      const createdCart = await eCommService.createCart(cartRequest);
      // Store initial cart state
      previousCart = [...cart];
      id = createdCart.id;
      commit('setCartId', id);

      if (shippingDetails) {
        readyCart = await eCommService.addAddress({
          cartId: id,
          addresses: [
            {
              address: {
                deliveryAddress: {
                  address1: shippingDetails.address,
                  address2: shippingDetails.address2,
                  city: shippingDetails.city,
                  provinceCode: shippingDetails.state,
                  countryCode: shippingDetails.country,
                  zip: shippingDetails.postalCode,
                  firstName: shippingDetails.firstName,
                  lastName: shippingDetails.lastName,
                  phone: shippingDetails.phoneNumber,
                },
              },
              selected: true,
            },
          ],
        });

        commit('setDeliveryAddressId', readyCart.deliveryAddressId, { root: true });
      }
    }
    const {
      amountOffInCents: discountAmount = 0,
      discount,
      totalPrice = 0,
      subTotal = 0,
      shippingAmount,
      shippingRates,
    } = readyCart || {};

    const couponCode = discount?.name ?? null;
    const couponData = couponCode ? {
      discountPrice: discount?.amountOff ?? 0,
      couponType: discount?.type ?? null,
    } : null;

    const orderObj = {
      costBreakdown: {
        totalCost: totalPrice,
        productTax: 0,
        productCost: subTotal,
        productShipping: shippingAmount,
        couponData,
        ...(discountAmount ? { discountAmount } : {}),
        ...(couponCode ? { couponName: couponCode } : {}),
      },
      shippingRateDetails: shippingRates ? shippingRates[0] : null,
      totalDuties: null,
    };

    const requestBody = formatOrderRequestBody({
      shippingDetails,
      cart: mapCartLinesForPaymentGateway(cart, rootGetters.cartProducts.shopifyItems),
      costBreakdown: orderObj.costBreakdown,
      shippingRateDetails: orderObj.shippingRateDetails,
      customerId: rootGetters.customerId,
      isPosOn: rootGetters.isPosOn,
      includesConsole: rootGetters.includesConsole,
    });

    let response;
    if (rootGetters.plainShopifyDraftOrderID) {
      response = await ordersApi.updateOrder({ draftOrderId: rootGetters.plainShopifyDraftOrderID, requestBody });
    } else {
      response = await ordersApi.createOrder({ requestBody }); // create Draft Order in Shopify to get taxes and duties
      commit('setShopifyDraftOrderDetails', { draftOrderName: response.data.draftOrderName, draftOrderId: response.data.draftOrderId }, { root: true });
    }

    const costBreakdown = {
      ...requestBody.costBreakdown, // this has coupon data
      ...response.data.finalCostBreakdown, // this had final cost data from shopify
    };

    commit('updateOrder', {
      ...orderObj,
      costBreakdown,
    });
    // commit('setFinalCostBreakdown', costBreakdown, { root: true });

    return orderObj;
  } catch (err) {
    throw new Error(err);
  }
}

export default {
  actions: {
    async createCheckout({ rootGetters, rootState, commit }, { shippingDetails, eCommService }) {
      console.log('store.js - action - createCheckout');

      commit('setProcessingTotalCost', true, { root: true });
      const { cartId } = rootState.checkout;

      try {
        await createOrUpdateShopifyCart({
          shippingDetails,
          eCommService,
          commit,
          cart: rootGetters.cart,
          rootGetters,
        }, cartId);
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        console.error(err);
        if (err.name === 'ProductAvailabilityError') {
          commit('SET_SHIPPING_ERROR', err.message, { root: true });
          commit('SET_SHIPPING_INVALID', ['country'], { root: true });
        } else {
          commit('SET_SHIPPING_ERROR', err, { root: true });
        }
        commit('setProcessingTotalCost', false, { root: true });
      }
    },
    async updateCheckout({ rootGetters, rootState, commit }, { shippingDetails, eCommService }) {
      console.log('store.js - action - updateCheckout');

      commit('setProcessingTotalCost', true, { root: true });
      const { cartId } = rootState.checkout;

      try {
        await createOrUpdateShopifyCart({
          shippingDetails,
          eCommService,
          commit,
          cart: rootGetters.cart,
          rootGetters,
        }, cartId);

        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        if (err.name === 'ProductAvailabilityError') {
          commit('SET_SHIPPING_ERROR', err.message, { root: true });
          commit('SET_SHIPPING_INVALID', ['country'], { root: true });
        } else {
          commit('SET_SHIPPING_ERROR', err, { root: true });
        }
        commit('setProcessingTotalCost', false, { root: true });
      }
    },
    async confirmOrder({
      commit, rootState, dispatch, rootGetters,
    }) {
      commit('SET_PROCESSING_PAYMENT_METHOD', true, { root: true });
      commit('SET_PAYMENT_ATTEMPT_ERROR', '', { root: true });

      console.log('store.js - action - confirmOrder');

      const { shipping, order } = rootGetters;
      const { cart } = rootState.checkout;

      try {
        const isExistingCustomer = await dispatch('getCustomer', shipping.email);

        if (isExistingCustomer) {
          await dispatch('updateCustomer', { shippingDetails: shipping, id: rootState.checkout.customerId });
        } else {
          await dispatch('createCustomer', { shippingDetails: shipping });
        }

        const { customerId: existingCustomerId } = rootState.checkout;
        const cartItemsTransformed = mapCartLinesForPaymentGateway(cart, rootGetters.cartProducts.shopifyItems);

        if (rootState.checkout.affirmToken) {
          const res = await ordersApi.createSetupIntent({
            customerId: existingCustomerId,
            paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id,
            shopifyDraftOrderId: rootState.checkout.draftOrderId,
            paymentMethod: 'Affirm',
          });
          commit('setSetupIntentId', res.data.id, { root: true });
        } else {
          const res = await ordersApi.createPaymentIntent({
            costBreakdown: rootGetters.order.costBreakdown,
            shopifyDraftOrderName: rootState.checkout.draftOrderName,
            shopifyDraftOrderId: rootState.checkout.draftOrderId,
            paymentMethod: rootState.checkout.paymentInformation?.paymentMethod,
            customerId: existingCustomerId,
          });

          const paymentIntentId = res.data.paymentIntentId;
          commit('setPaymentIntentId', paymentIntentId, { root: true });
        }

        const membership = getMembership({ state: rootState, getters: rootGetters });
        if (membership) {
          await dispatch('updateCustomerWithDefaultPaymentMethod');
        }

        const {
          new_customer: newCustomer, affirmToken, draftOrderId,
        } = rootState.checkout;

        const requestBody = {
          email: shipping.email,
          shippingInfo: {
            address: shipping.address,
            address2: shipping.address2,
            city: shipping.city,
            state: shipping.state,
            country: shipping.country,
            postalCode: shipping.postalCode,
            firstName: shipping.firstName,
            lastName: shipping.lastName,
            phoneNumber: shipping.phoneNumber,
            email: shipping.email,
          },
          costBreakdown: order.costBreakdown,
          shippingRateDetails: order.shippingRateDetails,
          productList: cartItemsTransformed,
          membership,
          affirmLoanId: affirmToken,
          customerId: existingCustomerId,
          paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id,
          options: {
            paymentMethod: rootState.checkout.paymentInformation?.paymentMethod,
            retail: rootState?.app?.location || null,
            insideSale: rootState?.app?.representative || null,
          },
          sessionData: sessionData.get(),
          newCustomer,
          paymentIntentId: rootState.checkout.paymentIntentId,
          setupIntentId: rootState.checkout.setupIntentId,
          draftOrderId,
          anonymousId: typeof window?.analytics?.user === 'function' ? window.analytics.user()?.anonymousId() : null,
        };

        const {
          data: {
            orderName, orderID, method, experiments,
          },
        } = await ordersApi.confirmOrder({ requestBody });

        commit('setConfirmation', {
          email: shipping.email,
          textPhoneNumber: shipping.phoneNumber,
          orderNumber: parseInt(orderName.split('#')[1], 10), // orderNumber needs to be an integer and cannot have # in it
          orderID,
          order: rootState.checkout.order,
          method,
          cart,
          isNewCustomer: newCustomer,
          serverExperiments: experiments, // TODO
          name: `${shipping.firstName} ${shipping.lastName}`,
          subscription: rootGetters.membershipInCart,
        }, { root: true });

        const products = rootGetters.cartProducts.shopifyItems.map(item => ({
          id: item.id,
          category: item.productType,
          title: `${item.title}, ${item.variant.productName}`,
          price: item.variant.price,
          img_url: item.image,
          variantId: item.variant.id,
        }));

        const analyticsObj = useAnalytics();
        analyticsObj.orderCompleted({ ...rootGetters.confirmation, products, packageInCart: !!rootGetters.packageInCart });

        commit('reset', null, { root: true });
      } catch (err) {
        throw new Error(err);
      }
    },
    async applyCoupon({ rootState, rootGetters, commit }, { coupon, eCommService }) {
      console.log('store.js - action - applyCoupon');
      const { cartId } = rootState.checkout;
      const shippingDetails = rootGetters.shipping;

      try {
        commit('setProcessingTotalCost', true, { root: true });

        await createOrUpdateShopifyCart({
          shippingDetails,
          eCommService,
          commit,
          cart: rootGetters.cart,
          couponName: coupon,
          rootGetters,
        }, cartId);

        commit('updateCouponError', '', { root: true });
        commit('updateCoupon', coupon, { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        commit('setProcessingTotalCost', false, { root: true });
        console.error(err);
        // TODO handle displaying correct error message
        commit('updateCouponError', err.message, { root: true });
      }
    },
    async removeCoupon({ rootState, rootGetters, commit }, { eCommService }) {
      console.log('store.js - action - removeCoupon');
      const { cartId } = rootState.checkout;
      const shippingDetails = rootGetters.shipping;

      try {
        commit('setProcessingTotalCost', true, { root: true });

        await createOrUpdateShopifyCart({
          shippingDetails,
          eCommService,
          commit,
          cart: rootGetters.cart,
          removeCoupon: true,
          rootGetters,
        }, cartId);
        commit('updateCoupon', '', { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        commit('setProcessingTotalCost', false, { root: true });
        console.error(err);
        // TODO handle displaying correct error message
        commit('updateCouponError', 'We are sorry. There was an error removing your promo code', { root: true });
      }
    },
    // handles sending a request to retrieve an existing customer from backend
    async getCustomer({ commit }, email) {
      console.log('store.js - action - getCustomer');
      let customer = {};

      try {
        const response = await ordersApi.getCustomer({ email });
        [customer] = response.data;

        if (customer) {
          commit('updateCustomerId', customer.id, { root: true });
          commit('setNewCustomer', customer.newSubCustomer, { root: true });
        }

        return customer;
      } catch (err) {
        // TODO handle errors
        console.error(err);

        throw new Error(err);
      }
    },
    // handles sending a request to create a new customer from backend
    async createCustomer({ commit }, payload) {
      console.log('store.js - action - createCustomer');
      let createdCustomer = {};

      try {
        const { shippingDetails } = payload;

        const requestBody = {
          email: shippingDetails.email,
          shippingDetails: {
            name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
            phone: shippingDetails.phoneNumber,
            address: {
              line1: shippingDetails.address,
              line2: shippingDetails.address2,
              city: shippingDetails.city,
              state: shippingDetails.state,
              country: shippingDetails.country,
              postal_code: shippingDetails.postalCode,
            },
          },
        };

        createdCustomer = await ordersApi.createCustomer(requestBody);

        commit('updateCustomerId', createdCustomer.data.id, { root: true });
        commit('setNewCustomer', createdCustomer.data.newSubCustomer, { root: true });

        return createdCustomer.data;
      } catch (err) {
        // TODO handle errors
        console.error(err);

        throw new Error(err);
      }
    },

    async updateCustomer({ rootState }, payload) {
      console.log('store.js - action - updateCustomer');
      let updatedCustomer = null;
      const { shippingDetails = {} } = payload;

      try {
        const requestBody = {
          paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id ?? null,
          ...(shippingDetails && {
            shippingDetails: {
              name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
              phone: shippingDetails.phoneNumber,
              address: {
                line1: shippingDetails.address,
                line2: shippingDetails.address2,
                city: shippingDetails.city,
                state: shippingDetails.state,
                country: shippingDetails.country,
                postal_code: shippingDetails.postalCode,
              },
            },
          }),
        };

        updatedCustomer = await ordersApi.updateCustomer({ id: rootState.checkout.customerId, requestBody });

        return updatedCustomer.data;
      } catch (err) {
        // TODO handle errors
        console.error(err);

        throw new Error(err);
      }
    },
    async updateCustomerWithDefaultPaymentMethod({ rootState }) {
      console.log('store.js - action - updateCustomerWithDefaultPaymentMethod');
      if (!rootState.checkout.paymentInformation?.paymentMethodObj?.id) throw new Error('No payment method found');

      try {
        const requestBody = { paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id };
        const updatedCustomer = await ordersApi.updateCustomer({ id: rootState.checkout.customerId, requestBody });

        return updatedCustomer.data;
      } catch (err) {
        console.error(err);
        throw new Error(err);
      }
    },
  },
};
