const cartFields = `
fragment cartFields on Cart {
  id
  checkoutUrl
  discountCodes {
    code
    applicable
  }
  discountAllocations {
    discountedAmount {
      amount
    }
  }
  cost {
    subtotalAmount {
      amount
    }
    totalAmount {
      amount
    }
    totalTaxAmount {
      amount
    }
  }
  delivery {
    addresses {
      id
      selected
    }
  }
  lines(first: 50) {
    edges {
      node {
        id
        merchandise {
          ... on ProductVariant {
            id
            title
            price {
              amount
            }
            compareAtPrice {
              amount
            }
            product {
              title
            }
          }
        }
        quantity
      }
    }
  }
  delivery {
    addresses(selected: true) {
      id
    }
  }
  deliveryGroups(first: 10) {
    edges {
      node {
        id
        cartLines(first: 50) {
          edges {
            node {
              id
              quantity
            }
          }
        }
        deliveryOptions {
          code
          description
          estimatedCost {
            amount
          }
          handle
          title
        }
      }
    }
  }
  buyerIdentity {
  countryCode
    email
    phone
    customer {
      id
      firstName
      lastName
      defaultAddress {
        address1
        address2
        city
        provinceCode
        zip
        countryCode
      }
    }
  }
}
`;

export const getCartQuery = `
query getCart($cartId: ID!) {
  cart(id: $cartId) {
    ...cartFields
  }
}
${cartFields}
`;

export const cartCreateMutation = `
mutation cartCreate($input: CartInput) {
  cartCreate(input: $input) {
    cart {
      ...cartFields
    }
    userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;

export const cartLinesAddMutation = `
mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ...cartFields
    }
    userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;

export const cartLinesRemoveMutation = `
mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ...cartFields
    }
    userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;

export const cartDeliveryAddressesAddMutation = `
mutation cartDeliveryAddressesAdd($addresses: [CartSelectableAddressInput!]!, $cartId: ID!) {
  cartDeliveryAddressesAdd(addresses: $addresses, cartId: $cartId) {
    cart {
     ...cartFields
    }
    userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;

export const cartDeliveryAddressesUpdateMutation = `
mutation cartDeliveryAddressesUpdate($addresses: [CartSelectableAddressUpdateInput!]!, $cartId: ID!) {
  cartDeliveryAddressesUpdate(addresses: $addresses, cartId: $cartId) {
    cart {
     ...cartFields
    }
    userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;

export const cartBuyerIdentityUpdateMutation = `
mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
  cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
    cart {
      ...cartFields
    }
   userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;

export const cartDiscountCodesUpdateMutation = `
mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
    cart {
      ...cartFields
    }
    userErrors {
      field
      message
      code
    }
    warnings {
      code
      target
      message
    }
  }
}
${cartFields}
`;
