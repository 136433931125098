// eslint-disable-next-line import/no-extraneous-dependencies
import { StorefrontApiClient } from '@shopify/storefront-api-client';
import * as cartMutations from './mutations';
import {
  handleGraphQLRequestErrors, CouponError, handleUserErrors, UnshippableError,
} from '../errors';

interface CartCreateParams {
  lines: {
    merchandiseId: string;
    quantity: number;
  }[];
  delivery: {
    addresses: {
      address: {
        deliveryAddress: {
          address1: string;
          address2: string;
          city: string;
          provinceCode: string;
          zip: string;
          countryCode: string;
          firstName: string;
          lastName: string;
          phone: string;
        };
      };
      selected: boolean;
    }[];
  }
}

interface ICartLineItem {
  merchandiseId: string;
  quantity: number;
}

interface AddedItemEdge {
  node: {
    merchandise: {
      id: string;
    };
  };
}

interface CreatedCart {
  lines: {
    edges: AddedItemEdge[];
  };
}

export async function getCart(cartId: string, client: StorefrontApiClient): Promise<any> {
  const { data, errors } = await client.request(cartMutations.getCartQuery, {
    variables: {
      cartId,
    },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);
  if (data?.cart?.userErrors?.length > 0) {
    handleUserErrors(data.cart.userErrors);
  }

  return data.cart;
}

export async function createCart(input: CartCreateParams, client: StorefrontApiClient): Promise<any> {
  const { data, errors } = await client.request(cartMutations.cartCreateMutation, {
    variables: {
      input,
    },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);

  if (data.cartCreate?.userErrors?.length > 0) {
    handleUserErrors(data.cartCreate.userErrors);
  }

  handlePossibleUnshippableItems(data.cartCreate.cart, input.lines);

  return data.cartCreate.cart;
}

export async function cartLinesAdd({ cartId, lines }: { cartId: string, lines: any[] }, client: StorefrontApiClient) {
  const { data, errors } = await client.request(cartMutations.cartLinesAddMutation, {
    variables: {
      cartId,
      lines,
    },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);

  if (data.cartLinesAdd.userErrors.length > 0) {
    handleUserErrors(data.cartLinesAdd.userErrors);
  }

  handlePossibleUnshippableItems(data.cartLinesAdd.cart, lines);

  return data.cartLinesAdd;
}

export async function cartLinesRemove({ cartId, lineIds }: { cartId: string, lineIds: string[] }, client: StorefrontApiClient) {
  const { data, errors } = await client.request(cartMutations.cartLinesRemoveMutation, {
    variables: {
      cartId,
      lineIds,
    },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);
  if (data.cartLinesRemove.userErrors.length > 0) {
    handleUserErrors(data.cartLinesRemove.userErrors);
  }

  return data.cartLinesRemove;
}

export async function cartDiscountCodesUpdate({ cartId, discountCodes }: {cartId: string, discountCodes: string[]}, client: StorefrontApiClient) {
  const { data, errors } = await client.request(cartMutations.cartDiscountCodesUpdateMutation, {
    variables: {
      cartId,
      discountCodes,
    },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);
  const isCouponNotApplicable = data.cartDiscountCodesUpdate.cart.discountCodes?.length > 0 && !data.cartDiscountCodesUpdate.cart.discountCodes?.[0]?.applicable;
  if (isCouponNotApplicable) throw new CouponError('Coupon code is not applicable');

  return data.cartDiscountCodesUpdate;
}

export async function cartDeliveryAddressesAdd({ cartId, addresses }: {cartId: string, addresses: any[]}, client: StorefrontApiClient) {
  const { data, errors } = await client.request(cartMutations.cartDeliveryAddressesAddMutation, {
    variables: {
      cartId,
      addresses,
    },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);
  if (data.cartDeliveryAddressesAdd.userErrors.length > 0) {
    handleUserErrors(data.cartDeliveryAddressesAdd.userErrors);
  }

  return data.cartDeliveryAddressesAdd;
}

export async function cartBuyerIdentityUpdate({ cartId, buyerIdentity }: {cartId: string, buyerIdentity: any}, client: StorefrontApiClient) {
  const { data: buyerIdentityData, errors: buyerIdentityErrors } = await client.request(cartMutations.cartBuyerIdentityUpdateMutation, {
    variables: {
      cartId,
      buyerIdentity,
    },
  });

  if (buyerIdentityErrors?.graphQLErrors) handleGraphQLRequestErrors(buyerIdentityErrors.graphQLErrors);
  if (buyerIdentityData.cartBuyerIdentityUpdate.userErrors.length > 0) {
    handleUserErrors(buyerIdentityData.cartBuyerIdentityUpdate.userErrors);
  }

  return buyerIdentityData.cartBuyerIdentityUpdate;
}

export async function cartDeliveryAddressesUpdate({ cartId, addresses, currentLines }: {cartId: string, addresses: any[], currentLines: ICartLineItem[]}, client: StorefrontApiClient) {
  const { data, errors } = await client.request(cartMutations.cartDeliveryAddressesUpdateMutation, {
    variables: { cartId, addresses },
  });

  if (errors?.graphQLErrors) handleGraphQLRequestErrors(errors.graphQLErrors);
  if (data.cartDeliveryAddressesUpdate.userErrors.length > 0) {
    handleUserErrors(data.cartDeliveryAddressesUpdate.userErrors);
  }

  handlePossibleUnshippableItems(data.cartDeliveryAddressesUpdate.cart, currentLines);
  return data.cartDeliveryAddressesUpdate;
}

function findUnshippableItems(attemptedItems: ICartLineItem[], addedItems: AddedItemEdge[]): ICartLineItem[] {
  const addedVariantIds = new Set(addedItems.map(edge => edge.node.merchandise.id));
  return attemptedItems.filter(item => !addedVariantIds.has(item.merchandiseId));
}

function handlePossibleUnshippableItems(createdCart: CreatedCart, attemptedItems: ICartLineItem[]): void {
  if (createdCart.lines.edges.length === 0) {
    throw new UnshippableError('These products are not available in your area.', attemptedItems.map(item => item.merchandiseId));
  }

  const unshippableItems = findUnshippableItems(attemptedItems, createdCart.lines.edges);

  if (unshippableItems.length > 0) {
    throw new UnshippableError('These products are not available in your area.', unshippableItems.map(item => item.merchandiseId));
  }
}
