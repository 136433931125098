import axios from 'axios';

const fightCampBackendLink = process.env.VUE_APP_API_URL;

export default {
  getOrder(paymentIntentId) {
    return axios.get(`${fightCampBackendLink}/orders?paymentIntentId=${paymentIntentId}`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createOrder({ requestBody }) {
    console.log('requestBody', requestBody);
    return axios.post(`${fightCampBackendLink}/orders`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  updateOrder({ draftOrderId, requestBody }) {
    return axios.put(`${fightCampBackendLink}/orders/${draftOrderId}`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  confirmOrder({ requestBody }) {
    return axios.post(`${fightCampBackendLink}/orders/confirm`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  getCustomer({ email }) {
    /*
      The email parameter is passed to encodeURIComponent to ensure that any special characters
      (including '+') are properly encoded. This is necessary because in URLs, '+' is interpreted as a space.
      So, if you want to include an actual '+' character in a URL, you need to URL encode it as '%2B'.
      encodeURIComponent takes care of this encoding for us.
    */
    return axios.get(`${fightCampBackendLink}/customers?email=${encodeURIComponent(email)}`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createCustomer(requestBody) {
    return axios.post(`${fightCampBackendLink}/customers`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  updateCustomer({ id, requestBody }) {
    return axios.put(`${fightCampBackendLink}/customers/${id}`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createSetupIntent(requestBody) {
    return axios.post(`${fightCampBackendLink}/setup-intents`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createPaymentIntent(requestBody) {
    return axios.post(`${fightCampBackendLink}/payment-intents`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
};
