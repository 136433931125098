import { DirectiveOptions } from 'vue';
// import { SegmentWrapper } from './segment';

export const TrackClick: DirectiveOptions = {
  bind(el, binding) {
    el.addEventListener('click', () => {
      console.log('TrackClick', binding.value);
      // SegmentWrapper.elementClicked(binding.value);
    });
  },
};
