// eslint-disable-next-line import/no-extraneous-dependencies
import { StorefrontApiClient } from '@shopify/storefront-api-client';
import {
  handleGraphQLRequestErrors, handleCheckoutUserErrors, ShippingError, CouponError,
} from './errors';
import {
  collectionQuery, productsQuery, checkoutCreateMutation, checkoutQuery, checkoutShippingAddressUpdateMutation, checkoutShippingLineUpdateMutation, checkoutLineItemsUpdateMutation, checkoutEmailUpdateMutation,
  removeCouponQuery, applyCouponQuery,
} from './queries';
import {
  FetchAllPagesParams, QueryCollectionReponse, QueryProductsResponse, GetProductsOrCollectionDataReturn, IShopifyProduct, IShopifyProductVariant, FormattedProduct,
  ICheckout, ShopifyShippingRate, ICouponResponse, LineItems, createCheckout, IShopifyService,
} from './types';
import * as cartModule from './cart/cart';

export class ShopifyService implements IShopifyService {
  private client: StorefrontApiClient;

  fetchAllPages: ({
    query, id, numProducts, cursor,
  }: FetchAllPagesParams) => Promise<IShopifyProduct[]>;

  constructor(shopifyClient: StorefrontApiClient) {
    this.client = shopifyClient;

    // fetchAllPages is a recursive function that will fetch all pages of Products and Collection query
    this.fetchAllPages = async ({
      query, id, numProducts = 100, cursor,
    }: FetchAllPagesParams) => {
      const variables = {
        ...(id && { id }),
        numProducts,
        ...(cursor && { cursor }),
      };

      const resp = await this.client.request(query, { variables });

      if (resp.errors) {
        const errorMessages = (resp?.errors?.graphQLErrors ?? []).map(e => e.message);
        errorMessages.forEach((error: string) => {
          throw new Error(error);
        });
      }

      // handle getting products or collection data from response
      const { productData, hasNextPage, endCursor } = getProductsOrCollectionData(resp as QueryProductsResponse | QueryCollectionReponse);

      if (hasNextPage) {
        const nextPageProductData = await this.fetchAllPages({
          query, cursor: endCursor, id, numProducts,
        });
        return [...productData, ...nextPageProductData];
      }
      return productData;
    };
  }

  async getProducts(): Promise<FormattedProduct[]> {
    try {
      const productsData = await this.fetchAllPages({ query: productsQuery });
      const productsResponse = this.formatProductData(productsData);

      return productsResponse;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  /*
    shopify id format example: gid://shopify/Collection/277084504153
  */
  async getCollection({ id }: { id: string }): Promise<FormattedProduct[]> {
    try {
      const collectionData = await this.fetchAllPages({ query: collectionQuery, id });
      const collectionResponse = this.formatProductData(collectionData);

      return collectionResponse;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getCheckout(checkoutId: string): Promise<ICheckout> {
    try {
      const { data, errors } = await this.client.request(checkoutQuery, {
        variables: {
          id: checkoutId,
        },
      });

      if (errors && errors.graphQLErrors) {
        console.log('graphQLErrors: ', errors);
        handleGraphQLRequestErrors(errors.graphQLErrors);
      }

      if (!data.node) {
        return {
          isReady: false,
          requiresShipping: false,
          shippingRates: null,
          subTotal: 0,
          totalPrice: 0,
          totalTax: 0,
          totalDuties: 0,
          shippingAmount: null,
        };
      }

      const {
        shippingLine, ready, availableShippingRates, subtotalPrice, lineItemsSubtotalPrice, totalPrice, totalTax, totalDuties, requiresShipping, discountApplications,
      } = data.node;
      console.log(data.node);

      if (ready && requiresShipping && availableShippingRates?.shippingRates?.length === 0) {
        throw new ShippingError('MISSING_SHIPPING_RATE');
      }

      // need to calculate percentage amount for discount in costBreakdown & refactor
      // TODO refactor
      const hasDiscount = discountApplications.edges.length > 0;
      let discountCalc;
      let discountType;
      let discountPrice;

      const lineItemsSubtotalPriceAmount = this.convertToCents(lineItemsSubtotalPrice.amount);
      const subTotalPriceAmount = this.convertToCents(subtotalPrice.amount);

      if (hasDiscount) {
        // TODO refact and make discountTypes constants
        discountType = discountApplications.edges[0].node.value.amount ? 'FIXED_AMOUNT' : 'PERCENTAGE';
        if (discountType === 'FIXED_AMOUNT') {
          discountCalc = this.convertToCents(discountApplications.edges[0].node.value.amount);
          discountPrice = parseFloat(discountApplications.edges[0].node.value.amount);
        } else {
          // TODO handle shipping coupons
          // should I actually do the percentage calculation here? or what if this is a shipping coupon ??
          discountCalc = lineItemsSubtotalPriceAmount - subTotalPriceAmount;
          discountPrice = discountApplications.edges[0].node.value.percentage;
        }
      }

      return {
        isReady: ready,
        requiresShipping,
        shippingRates: ready ? availableShippingRates?.shippingRates
          .map((rate: ShopifyShippingRate) => ({
            id: rate.handle,
            title: rate.title,
            price: this.convertToCents(rate.price.amount),
          })) : null,
        subTotal: this.convertToCents(lineItemsSubtotalPrice.amount),
        totalPrice: this.convertToCents(totalPrice.amount),
        totalTax: this.convertToCents(totalTax.amount),
        totalDuties: (ready && totalDuties) ? this.convertToCents(totalDuties.amount) : 0,
        ...(hasDiscount ? { discountAmount: discountCalc } : {}),
        ...(hasDiscount ? { couponCode: discountApplications?.edges[0]?.node?.code ?? null } : {}),
        ...(hasDiscount ? {
          couponData: {
            couponType: discountType,
            discountPrice,
          },
        } : {}),
        shippingAmount: (ready && shippingLine) ? this.convertToCents(shippingLine.price.amount) : null,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async createCheckout({
    email, shippingAddress, lineItems,
  }: createCheckout): Promise<{isReady: boolean, checkoutId: string}> {
    try {
      const input = { shippingAddress, lineItems, email };

      const { data, errors } = await this.client.request(checkoutCreateMutation, {
        variables: {
          input,
        },
      });

      if (errors?.graphQLErrors) {
        console.log('graphQLErrors: ', errors.graphQLErrors);
        handleGraphQLRequestErrors(errors.graphQLErrors);
      }

      if (data.checkoutCreate.checkoutUserErrors.length > 0) {
        console.log('checkoutUserErrors', data.checkoutCreate.checkoutUserErrors);
        handleCheckoutUserErrors(data.checkoutCreate.checkoutUserErrors, input.lineItems);
      }

      const { ready, id } = data.checkoutCreate.checkout;

      return {
        isReady: ready,
        checkoutId: data?.checkoutDiscountCodeApplyV2?.checkout?.id || id,
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  // this can handle updating address, line items, discounts, etc depending what is passed
  async updateCheckout({
    checkoutId, shippingAddress, lineItems, email,
  }: {
    checkoutId: string,
    shippingAddress: any,
    lineItems: LineItems[],
    email: string,
  }): Promise<{error?: string, checkoutId: string}> {
    try {
      let updatedCheckoutId = checkoutId;

      if (shippingAddress) {
        const { data, errors } = await this.client.request(checkoutShippingAddressUpdateMutation, {
          variables: {
            checkoutId: updatedCheckoutId,
            shippingAddress,
          },
        });

        if (errors?.graphQLErrors) {
          console.log('graphQLErrors: ', errors);
          handleGraphQLRequestErrors(errors.graphQLErrors);
        }

        if (data.checkoutShippingAddressUpdateV2.checkoutUserErrors.length > 0) {
          handleCheckoutUserErrors(data.checkoutShippingAddressUpdateV2.checkoutUserErrors);
        }

        updatedCheckoutId = data.checkoutShippingAddressUpdateV2.checkout.id;
      }

      if (lineItems.length > 0) {
        const { data, errors } = await this.client.request(checkoutLineItemsUpdateMutation, {
          variables: {
            checkoutId: updatedCheckoutId,
            lineItems,
          },
        });

        if (errors?.graphQLErrors) {
          console.log('graphQLErrors: ', errors);
          handleGraphQLRequestErrors(errors.graphQLErrors);
        }

        if (data.checkoutLineItemsReplace.userErrors.length > 0) {
          handleCheckoutUserErrors(data.checkoutLineItemsReplace.userErrors, lineItems);
        }

        updatedCheckoutId = data.checkoutLineItemsReplace.checkout.id;
      }

      if (email) {
        const { data, errors } = await this.client.request(checkoutEmailUpdateMutation, {
          variables: {
            checkoutId: updatedCheckoutId,
            email,
          },
        });

        if (errors?.graphQLErrors) {
          console.log('graphQLErrors: ', errors);
          handleGraphQLRequestErrors(errors.graphQLErrors);
        }

        if (data.checkoutEmailUpdateV2.checkoutUserErrors.length > 0) {
          handleCheckoutUserErrors(data.checkoutEmailUpdateV2.checkoutUserErrors);
        }

        updatedCheckoutId = data.checkoutEmailUpdateV2.checkout.id;
      }

      return {
        checkoutId: updatedCheckoutId,
      };
    } catch (err: any) {
      console.log(err);
      throw err;
    }
  }

  async updateCheckoutShippingLine({ checkoutId, shippingRateHandle }: {checkoutId: string, shippingRateHandle: string}):Promise<void> {
    try {
      const { data, errors } = await this.client.request(checkoutShippingLineUpdateMutation, {
        variables: {
          checkoutId,
          shippingRateHandle,
        },
      });

      if (errors?.graphQLErrors) {
        console.log('graphQLErrors: ', errors);
        handleGraphQLRequestErrors(errors.graphQLErrors);
      }

      if (data.checkoutShippingLineUpdate.checkoutUserErrors.length > 0) {
        handleCheckoutUserErrors(data.checkoutShippingLineUpdate.checkoutUserErrors);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async applyCoupon({ checkoutId, couponCode }: {checkoutId: string, couponCode: string}): Promise<ICouponResponse> {
    try {
      const { data: checkout } = await this.client.request(applyCouponQuery, {
        variables: {
          checkoutId,
          discountCode: couponCode,
        },
      });

      const { id, discountApplications } = checkout.checkoutDiscountCodeApplyV2.checkout;
      const respObj: ICouponResponse = { errors: [], checkoutId: id };

      if (checkout.checkoutDiscountCodeApplyV2.checkoutUserErrors.length > 0) {
        respObj.errors.push('Promo code is invalid');
      }

      /*
        Shopify will now give an error since the coupon is a valid one.
        If items are not part of the coupon it will just return the order with no discount applied
        TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
      */
      if (!discountApplications || discountApplications?.edges?.length === 0) {
        if (couponCode === 'UPGRADE1TO2G7X9Z4') {
          respObj.errors.push('Promo code is invalid with these items or email.');
        } else {
          respObj.errors.push('Promo code does not apply to these items');
        }
      }

      return respObj;
    } catch (err: any) {
      // TODO  refactor err message handling
      console.log(err);
      throw new CouponError(err.message);
    }
  }

  async removeCoupon({ checkoutId }: {checkoutId: string}): Promise<ICouponResponse> {
    try {
      const { data: checkout } = await this.client.request(removeCouponQuery, {
        variables: {
          checkoutId,
        },
      });

      const { id } = checkout.checkoutDiscountCodeRemove.checkout;
      const respObj: ICouponResponse = { errors: [], checkoutId: id };

      if (checkout.checkoutDiscountCodeRemove.checkoutUserErrors.length > 0) {
        respObj.errors.push('Something went wrong. Please try again.');
      }

      return respObj;
    } catch (err) {
      console.log(err);
      throw new CouponError('Something went wrong. Please try again.');
    }
  }

  async createCart(input: any): Promise<any> {
    const cart = await cartModule.createCart(input, this.client);

    return this.transformCartData(cart);
  }

  async cartLinesRemove({ cartId, lineIds }: {cartId: string, lineIds: string[]}): Promise<any> {
    return cartModule.cartLinesRemove({ cartId, lineIds }, this.client);
  }

  async cartLinesReplace({ cartId, lines }: {cartId: string, lines: any[]}): Promise<any> {
    const currentCart = await cartModule.getCart(cartId, this.client);
    const currentCartLineIds = currentCart.lines.edges.map((edge: any) => edge.node.id);
    await cartModule.cartLinesRemove({ cartId, lineIds: currentCartLineIds }, this.client);

    const { cart } = await cartModule.cartLinesAdd({ cartId, lines }, this.client);
    return this.transformCartData(cart);
  }

  async addAddress({ cartId, addresses }: {cartId: string, addresses: any}): Promise<any> {
    const { cart } = await cartModule.cartDeliveryAddressesAdd({ cartId, addresses }, this.client);
    return this.transformCartData(cart);
  }

  async updateAddress({ cartId, addresses, email }: {cartId: string, addresses: any, email: string}): Promise<any> {
    const currentCart = await cartModule.getCart(cartId, this.client);
    const currentLines = currentCart.lines.edges.map((edge: any) => ({
      merchandiseId: edge.node.merchandise.id,
      quantity: edge.node.quantity,
    }));

    const buyerIdentity = {
      email,
      countryCode: addresses?.[0]?.address?.deliveryAddress?.countryCode,
      phone: addresses?.[0]?.address?.deliveryAddress?.phone,
    };

    await cartModule.cartBuyerIdentityUpdate({ cartId, buyerIdentity }, this.client);
    const { cart } = await cartModule.cartDeliveryAddressesUpdate({ cartId, addresses, currentLines }, this.client);

    return this.transformCartData(cart);
  }

  async updateCartCoupon({ cartId, discountCodes, currentSubtotalInCents }: {cartId: string, discountCodes: string[], currentSubtotalInCents: number}): Promise<any> {
    const { cart } = await cartModule.cartDiscountCodesUpdate({ cartId, discountCodes }, this.client);

    const hasCartDiscountType = cart.discountCodes[0]?.applicable && cart.discountAllocations.length === 0;
    const transformedCart = this.transformCartData(cart);

    if (hasCartDiscountType) {
      return {
        ...transformedCart,
        subTotal: currentSubtotalInCents,
        discount: {
          name: cart.discountCodes[0]?.code ?? null,
          amountOff: parseFloat(((currentSubtotalInCents - transformedCart.subTotal) / 100.0).toFixed(2)),
          type: 'FIXED_AMOUNT',
        },
        amountOffInCents: currentSubtotalInCents - transformedCart.subTotal,
      };
    }

    return transformedCart;
  }

  private transformCartData(cart: any) {
    const deliveryOptions = cart.deliveryGroups?.edges[0]?.node?.deliveryOptions ?? [];
    const totalDiscountAmount = cart.discountAllocations.reduce((acc: number, allocation: any) => acc + this.convertToCents(allocation.discountedAmount.amount), 0);

    return {
      id: cart.id,
      deliveryAddressId: cart.delivery.addresses[0]?.id ?? null,
      discount: {
        name: cart.discountCodes[0]?.code ?? null,
        amountOff: parseFloat((totalDiscountAmount / 100).toFixed(2)),
        type: 'FIXED_AMOUNT',
      },
      amountOffInCents: totalDiscountAmount,
      subTotal: this.convertToCents(cart.cost.subtotalAmount.amount),
      totalPrice: this.convertToCents(cart.cost.totalAmount.amount),
      shippingAmount: this.convertToCents(deliveryOptions[0]?.estimatedCost?.amount ?? 0),
      shippingRates: deliveryOptions.length > 0 ? deliveryOptions.map((rate: any) => ({
        id: rate.handle,
        title: rate.title,
        price: this.convertToCents(rate.estimatedCost.amount),
      })) : null,
    };
  }

  /* eslint-disable class-methods-use-this */
  private convertToCents(amount: string): number {
    return Math.round(parseFloat(amount) * 100);
  }

  private formatProductData(data: IShopifyProduct[]): FormattedProduct[] {
    return data.map((edge: IShopifyProduct) => {
      const prod = edge?.node;
      return {
        id: prod?.id,
        title: prod?.title,
        description: prod?.description,
        image: prod?.featuredImage?.url,
        images: prod?.images?.edges?.map((img) => img.node.url) ?? [],
        productType: prod?.productType,
        tags: prod?.tags,
        variants: prod?.variants?.edges?.map((variantEdge: IShopifyProductVariant) => {
          const variant = variantEdge?.node;
          /*
          TODO key value and name value are here so the shopify data will work with our old code.
          When refactoring / turning off the isShopifyon flag we can think about renaming these or only using id instead of key/etc.
          */
          return {
            id: variant?.id,
            key: variant?.id,
            name: variant?.title,
            title: variant?.title,
            sku: variant?.sku,
            compareAtPrice: (variant.compareAtPrice) ? this.convertToCents(variant.compareAtPrice.amount) : null,
            price: this.convertToCents(variant?.price?.amount) || 0,
            uid: variant?.uid?.value.trim() ?? null,
          };
        }),
        // TODO should we name this something like stripeData?
        metadata: {
          stripeSubId: prod?.stripeSubId?.value.trim(),
          stripeTrialDays: prod?.stripeTrialDays?.value.trim(),
          stripeCoupon: prod?.stripeCoupon?.value.trim(),
          membershipGID: prod?.membershipGID?.value.trim(),
          monthlyPrice: prod?.monthlyPrice?.value.trim(),
        },
      };
    });
  }
}

function getProductsOrCollectionData(queryResp: QueryCollectionReponse | QueryProductsResponse): GetProductsOrCollectionDataReturn {
  if (queryResp?.data && 'products' in queryResp.data) {
    return {
      productData: queryResp.data.products.edges,
      hasNextPage: queryResp.data.products.pageInfo.hasNextPage,
      endCursor: queryResp.data.products.pageInfo.endCursor,
    };
  }

  if (queryResp?.data && 'collection' in queryResp.data) {
    return {
      productData: queryResp.data.collection.products.edges,
      hasNextPage: queryResp.data.collection.products.pageInfo.hasNextPage,
      endCursor: queryResp.data.collection.products.pageInfo.endCursor,
    };
  }

  return {
    productData: [],
    hasNextPage: false,
    endCursor: '',
  };
}
